/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400&family=Roboto:wght@100&display=swap");

.App {
  margin: 0 2rem;
}

body {
  background-color: #f9f8f8;
}

/* ********************************************** */
/* ABOVE THE FOLD */

.navbar {
  font-family: Inconsolata;
  font-size: 1.5rem;
  color: #4986bb;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 3rem 10rem 5rem 5rem;
  /* 
  @media (max-width: 700px) {
    font-size: 1rem;
    margin: 2rem 0 5rem 0;
  } */
}

@media (max-width: 700px) {
  .navbar {
    font-size: 1.2rem;
    margin: 2rem 2rem 5rem 0;
  }
}

.header {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 2.7rem;
  font-weight: 700;
  color: #4986bb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  animation: slide-up 1.5s;

  /* @media (max-width: 700px) {
    font-size: 1.8rem;
  } */
}

/* @media (max-width: 700px) {
  .header {
    font-size: 1rem;
  }
} */

#name {
  color: #325f86;
}

#stickyArrowText {
  position: sticky;
  color: #0b4477;
  display: flex;
  justify-content: center;
  margin: 10rem auto 0 auto;
}

#arrowDown {
  position: sticky;
  color: #0b4477;
  transform: scale(2.9);
  display: flex;
  justify-content: center;
  margin: 3rem auto 0 auto;
}

@media all and (max-width: 1000px) {
  .header {
    font-size: 1.8rem;
  }
}

@media all and (max-width: 700px) {
  .header {
    font-size: 1.2rem;
  }
}

/* ********************************************** */
/* ABOUT - SECTION 01 */

/* #section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

#aboutSection {
  display: flex;
  flex-direction: column;
  font-family: Inconsolata;
  color: black;
  margin: 10rem 0 0 0;
  /* display: grid;
  grid-template-columns: 30% 70%; */

  @media (max-width: 700px) {
    margin: 0;
  }
}

#aboutContainer {
  justify-content: center;
  align-self: center;
  width: 50%;
  /* display: flex;
  flex-direction: column; */

  @media (max-width: 700px) {
    width: 75%;
  }
}

.aboutContact {
  text-align: center;
  /* margin-top: 3rem; */
}

/* add underline animation here */
#about04 a:hover {
  color: pink;
}

@media (max-width: 700px) {
  #aboutSection {
    margin-top: 2rem;
  }

  #aboutContainer {
    width: 90%;
  }
}

/* ********************************************** */
/* TECHNOLOGIES - SECTION 01 */

#technologiesContainer {
  margin: 2rem 0 2rem 0;
  justify-content: center;
  align-self: center;
  width: 75%;

  @media (max-width: 700px) {
    margin: 0;
  }
}

#technologiesGrid {
  font-family: Inconsolata;
  width: 100%;
  display: grid;
  /* grid-template-columns: 33% 33% 33%%; */

  /* @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
  } */
}

#technologiesTitle {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
}

.techLine {
  width: 100%;
  color: #4986bb;
}

.techRow {
  text-align: center;
  display: grid;
  grid-template-columns: 33%33%33%;
  padding: 0;
}

.techItem {
  color: black;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
}

.techType {
  font-size: 1.2rem;
  color: #0b4477;
}

.techList {
  font-size: 1rem;
  width: min-content;
}

@media (max-width: 700px) {
  .techList {
    width: 100%;
  }
}

@media (max-width: 700px) {
  #technologiesGrid {
    display: flex;
    flex-direction: column;
  }

  .techRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .techItem {
    margin-bottom: 2rem;
  }

  .techLine {
    display: none;
  }
}

/* ********************************************** */
/* PROJECTS - SECTION 02 */

#projectsSection {
  font-family: Inconsolata;
  color: #4986bb;
  margin: 20rem 0 0 0;
  display: grid;
  grid-template-columns: 30% 25% 40%;
}

#projects01 {
  color: #4986bb;
  font-size: 2rem;
  margin: 0 0 0 5rem;
}

#projects02 {
  color: #4986bb;
  /* margin: 0 0 0 5rem; */
}

#projects03 {
  margin: 0 0 0 10rem;
  width: 15rem;
}

/* ********************************************** */
/* SINGLE PROJECTS */

.singleProjectContainer {
  display: flex;
  margin: 8rem 5rem;
  padding: 2rem;
  height: 40rem;

  /* @media (max-width: 800px) {
    flex-direction: column;
    margin: 0;
    padding: 1rem;
    height: auto;
  } */
}

.singleProjectInfo {
  font-family: Inconsolata;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  width: 50%;
}

.singleProjectInfoFull {
  font-family: Inconsolata;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  width: 100%;
}

.singleProjectInfoTitle {
  font-size: 1.4em;
  color: #0b4477;
}

.singleProjectDescription {
}

.singleProjectItem {
  display: flex;
}

.singleProjectDetails {
  margin-top: 6rem;
}

.singleProjectImage {
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center; */
  align-self: flex-start;
  margin-left: 5rem;
  width: 50%;
  /* height: 20rem; */
  /* max-width: 50rem;
  max-height: 45rem; */

  @media (max-width: 700px) {
    width: auto;
    margin-left: 0;
  }
}

.singleProjectDetail {
  margin-left: 50%;
}

.singleProjectHRTitle {
  margin-bottom: 2rem;
}

.singleProjectHR {
  margin: 2rem 0;
}

@media (max-width: 1000px) {
  .singleProjectContainer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 1rem;
    height: auto;
  }

  .singleProjectImage {
    margin: 0 0 3rem 0;
    align-self: center;
    width: 80%;
  }

  .singleProjectInfo {
    margin: 0;
    width: 70%;
  }

  .singleProjectDetails {
    /* width: 80%; */
  }

  .singleProjectDetail {
  }
}

@media (max-width: 700px) {
  .singleProjectContainer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 1rem;
    height: auto;
  }

  .singleProjectImage {
    margin: 0 0 3rem 0;
    align-self: center;
    width: 100%;
  }

  .singleProjectInfo {
    margin: 0;
    width: 90%;
  }

  .singleProjectDetails {
    /* width: 80%; */
  }

  .singleProjectDetail {
    /* font-size: 1rem; */
    margin-left: 30%;
  }
}

/* ********************************************** */

/* PROJECTS - VTS BOARD */

#vtsContainer {
  font-family: Inconsolata;
  color: #0b4477;
  margin: 5rem 0 0 0;
  display: flex;
  /* display: grid;
  grid-template-columns: 30% 25% 40%; */
}

/* #vtsTitle {
  font-size: 2rem;
  margin: 0 5rem 0 8rem;
  align-self: center;
} */

#vtsImg {
  margin: 0 2rem 0 0;
  width: auto;
  height: auto;
}

/* ********************************************** */
/* PROJECTS - PROXIMA */

#proximaContainer {
  font-family: Inconsolata;
  color: #0b4477;
  /* margin: 5rem 0 0 40%; */
  display: flex;
  /* display: grid;
  grid-template-columns: 30% 25% 40%; */
}

/* #proximaTitle {
  font-size: 2rem;
  margin: 0 5rem 0 5rem;
  align-self: center;
} */

#proximaImg {
  margin: 0 2rem 0 0;
  width: auto;
  height: auto;
}

/* ********************************************** */

/* .linkbar {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 3rem;
  height: 5rem;
}

.link {
  margin-top: 1rem;
  list-style-type: none;
} */

/* ********************************************** */

.links {
  font-family: "Inconsolata";
  color: #4986bb;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  text-decoration: none;
}

.link {
  font-family: "Inconsolata";
  color: #4986bb;
  margin: 0 15rem 0 0;
}

.linkTwo {
  margin-left: 5rem;
}
.projectsSectionContainer {
  font-family: "Inconsolata";
  color: #4986bb;
  text-decoration: none;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  margin: 5rem 0 0 0;
}

.projectsContainer {
  font-family: "Inconsolata";
  color: #0b4477;
  text-decoration: none;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  margin: 1rem 0 3rem 0;
}

/* .projectMainInfo:hover {
  opacity: 0.5;
} */

.projectsContainer:hover {
  opacity: 0.5;
}

.project {
  font-family: "Inconsolata";
  /* color: #4986bb; */
  display: grid;
  grid-template-columns: 75% 25%;
}

.project-technologies {

}

@media (max-width: 700px) {
  .project-technologies {
    display: none;
  }
}

.projectLinks {
  display: grid;
  grid-template-columns: repeat(3, 33%);
}

@media (max-width: 700px) {
  .projectLinks {
    display: none;  
  }
}

.projectLink:hover {
  opacity: 1;
}

@media (max-width: 700px) {
  .projectsSectionContainer {
    grid-template-columns: repeat(2, 50%);
}

  .projectsContainer {
    grid-template-columns: repeat(3, 33%);
  }

  @media (max-width: 700px) {
    .projectsContainer {
      grid-template-columns: repeat(2, 50%);
  }
}

  .projectLink {
    display: none;
  }

  .codeColumn {
    display: none;
  }

  .githubLink {
    display: none;
  }

  .links {
    grid-template-columns: repeat(3, 33%);
  }
}

a {
  text-decoration: none;
  color: #325f86;
}

/* a:hover {
  color: pink;
} */

@keyframes slide-up {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* .underline {
  display: inline;
  position: relative;
  overflow: hidden;
}
.underline:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: -5px;
  background: #000;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.underline:hover:after,
.underline:focus:after,
.underline:active:after {
  left: 0;
  right: auto;
  width: 100%;
} */

.underline {
  --s: 2px; /* the thickness of the line */
  --c: #325f86; /* the color */

  /* color: #0000; */
  padding-bottom: 2px;
  background: linear-gradient(90deg, var(--c) 50%, #0000)
      calc(35% - var(--_p, 0%)) / 200% 35%,
    linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
  /* changing the 100% above moves the line above the text */
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.5s;
}

.underline:hover {
  --_p: 100%;
}

.uInfo:hover {
  --_p: 20%;
}
